<template>
    <div class="content-library-list">
        <PageHeading :breadcrumbs="breadcrumbs" title="Content Library" />

        <FullPageLoadingSpinner :show="$wait.is($options.LoadingFlag.ConvoGet)" />

        <div class="m-b-2 content-library-list__search">
            <Input
                id="search"
                ref="searchTerm"
                v-model="searchTerm"
                type="text"
                name="search"
                label="Search convo"
                placeholder="Type convo ID or name"
                :has-margin-bottom="false"
                :has-margin-top="true"
                :show-clear-icon="true"
                class="w-25"
                default-icon="magnifying-glass"
                @input="handleSearch"
            />
        </div>

        <Datatable
            :data="convoListView.convoList"
            :columns="[
                {
                    value: 'name',
                    header: 'CONVO',
                    type: 'text',
                },
                {
                    value: 'type',
                    header: 'TYPE',
                    type: 'text',
                    width: '15%',
                },
                {
                    value: 'countries',
                    header: 'COUNTRY',
                    type: 'slot',
                    width: '15%',
                },
                {
                    value: 'yougovPreferencesChannels',
                    header: 'CHANNELS',
                    type: 'slot',
                    width: '15%',
                },
                {
                    value: 'createdAt',
                    header: 'CREATED ON',
                    type: 'date',
                    width: '15%',
                    format: config.dateFormat,
                },
                {
                    value: 'actions',
                    class: 'table-row-actions',
                    header: '',
                    type: 'slot',
                    width: '5%',
                },
            ]"
        >
            <template v-slot:countries="{ item }">
                {{ getCountryNameByCode(item.countries) }}
            </template>

            <template v-slot:yougovPreferencesChannels="{ item }">
                <router-link
                    v-for="(channel, index) in item.yougovPreferencesChannels"
                    :key="index"
                    :to="`/channels/${channel}`"
                    class="channel"
                >
                    <Badge type="neutral">{{ channel }}</Badge>
                </router-link>
            </template>

            <template v-slot:actions="{ item }">
                <TableRowActions
                    :menu-actions="[
                        {
                            label: 'Edit Convo',
                            action: 'edit-convo',
                            icon: 'actions-edit',
                        },
                    ]"
                    @action="(action) => handleAction(action, item)"
                />
            </template>
        </Datatable>

        <Paging
            :total="convoListView.convoList.total"
            :size="convoListView.convoList.size"
            :page="convoListView.convoList.page"
            @pageSelected="handlePageChange"
        />

        <Stepper :nav-is-opened="navIsOpened" :is-loading="false">
            <DropDownButton
                :drop-down-items="convoTypesDropdownList"
                @click-dropdown-item="onClickDropDownItem"
            >
                New Convo
            </DropDownButton>
        </Stepper>
    </div>
</template>

<script>
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import { LoadingFlag } from '@/store/enums/index.ts';
import { Routes } from '@/enums/index.ts';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import TableRowActions from '@/components/TableRowActions';
import PageHeading from '@/components/ui/PageHeading';
import Badge from '@/components/ui/Badge';
import Datatable from '@/components/Datatable';
import { mapActions, mapGetters } from 'vuex';
import Stepper from '@/components/ui/Stepper';
import Input from '@/components/forms/Input';
import Paging from '@/components/Paging';
import config from '@/config';
import convoTypes from '@/json/convoTypes';
import DropDownButton from '@/components/ui/DropDown/DropDownButton';
import countryList from '@/json/countries.ts';

export default {
    name: 'ContentLibraryList',
    components: {
        FullPageLoadingSpinner,
        TableRowActions,
        PageHeading,
        Datatable,
        Stepper,
        Paging,
        Input,
        DropDownButton,
        Badge,
    },
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            config,
            convoToBeDeleted: null,
            dialogIsVisible: false,
            searchTerm: '',
            breadcrumbs: [new Breadcrumb('Content Library', { name: 'content-library-list' })],
            convoTypesDropdownList: convoTypes.filter(
                (convoType) => convoType.availableInMessageLibrary,
            ),
            convoTypesNames: convoTypes
                .filter((convoType) => convoType.availableInMessageLibrary)
                .map((o) => o.name),
        };
    },
    computed: {
        ...mapGetters('convoList', ['convoListView']),
    },
    created() {
        this.$options.LoadingFlag = LoadingFlag;
        this.getConvos({
            channelId: -1,
            convoTypes: this.convoTypesNames,
            redirectName: Routes.ContentLibraryMessages,
        });
    },
    methods: {
        ...mapActions('convoList', ['getConvos']),
        handlePageChange(event, page) {
            this.getConvos({
                page,
                channelId: -1,
                convoTypes: this.convoTypesNames,
                redirectName: Routes.ContentLibraryMessages,
            });
        },
        handleSearch() {
            this.getConvos({
                channelId: -1,
                convoTypes: this.convoTypesNames,
                redirectName: Routes.ContentLibraryMessages,
                q: this.searchTerm,
                page: this.convoListView.convoList.page,
            });
        },
        handleAction(action, item) {
            const actionMap = {
                'edit-convo': () =>
                    this.$router.push({
                        name: Routes.ContentLibraryMessages,
                        params: { convoId: item.id },
                    }),
            };
            if (!actionMap[action]) {
                this.$log.warn('no handler for action');
                return;
            }
            actionMap[action]();
        },
        async onClickDropDownItem(item) {
            this.$router.push({
                name: Routes.ContentLibraryCreate,
                params: { convoType: item.name },
            });
        },
        getCountryNameByCode(isoCountryCodeArr) {
            if (!isoCountryCodeArr) {
                return '';
            }
            const country = isoCountryCodeArr.map(
                (iso2Code) => countryList.find((c) => c.iso2 === iso2Code).name,
            );
            return country.join(', ');
        },
    },
};
</script>

<style lang="scss" scoped>
.channel {
    padding-right: 0.5rem;
    display: inline-block;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-library-list"},[_c('PageHeading',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":"Content Library"}}),_c('FullPageLoadingSpinner',{attrs:{"show":_vm.$wait.is(_vm.$options.LoadingFlag.ConvoGet)}}),_c('div',{staticClass:"m-b-2 content-library-list__search"},[_c('Input',{ref:"searchTerm",staticClass:"w-25",attrs:{"id":"search","type":"text","name":"search","label":"Search convo","placeholder":"Type convo ID or name","has-margin-bottom":false,"has-margin-top":true,"show-clear-icon":true,"default-icon":"magnifying-glass"},on:{"input":_vm.handleSearch},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('Datatable',{attrs:{"data":_vm.convoListView.convoList,"columns":[
            {
                value: 'name',
                header: 'CONVO',
                type: 'text',
            },
            {
                value: 'type',
                header: 'TYPE',
                type: 'text',
                width: '15%',
            },
            {
                value: 'countries',
                header: 'COUNTRY',
                type: 'slot',
                width: '15%',
            },
            {
                value: 'yougovPreferencesChannels',
                header: 'CHANNELS',
                type: 'slot',
                width: '15%',
            },
            {
                value: 'createdAt',
                header: 'CREATED ON',
                type: 'date',
                width: '15%',
                format: _vm.config.dateFormat,
            },
            {
                value: 'actions',
                class: 'table-row-actions',
                header: '',
                type: 'slot',
                width: '5%',
            } ]},scopedSlots:_vm._u([{key:"countries",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCountryNameByCode(item.countries))+" ")]}},{key:"yougovPreferencesChannels",fn:function(ref){
        var item = ref.item;
return _vm._l((item.yougovPreferencesChannels),function(channel,index){return _c('router-link',{key:index,staticClass:"channel",attrs:{"to":("/channels/" + channel)}},[_c('Badge',{attrs:{"type":"neutral"}},[_vm._v(_vm._s(channel))])],1)})}},{key:"actions",fn:function(ref){
        var item = ref.item;
return [_c('TableRowActions',{attrs:{"menu-actions":[
                    {
                        label: 'Edit Convo',
                        action: 'edit-convo',
                        icon: 'actions-edit',
                    } ]},on:{"action":function (action) { return _vm.handleAction(action, item); }}})]}}])}),_c('Paging',{attrs:{"total":_vm.convoListView.convoList.total,"size":_vm.convoListView.convoList.size,"page":_vm.convoListView.convoList.page},on:{"pageSelected":_vm.handlePageChange}}),_c('Stepper',{attrs:{"nav-is-opened":_vm.navIsOpened,"is-loading":false}},[_c('DropDownButton',{attrs:{"drop-down-items":_vm.convoTypesDropdownList},on:{"click-dropdown-item":_vm.onClickDropDownItem}},[_vm._v(" New Convo ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div v-click-outside="onClickOutside" class="dropdown-button">
        <DropDown :is-open="showDropDown" class="dropdown-button-dropdown">
            <DropDownItem
                v-for="(item, idx) in dropDownItems"
                :key="idx"
                :icon="item.icon"
                @click="$emit('click-dropdown-item', item)"
            >
                {{ item.title }}
            </DropDownItem>
        </DropDown>

        <Button
            :has-border="true"
            :right-icon="buttonIcon"
            :icon-size="10"
            @click="showDropDown = !showDropDown"
        >
            <slot />
        </Button>
    </div>
</template>

<script>
import Button from '@/components/ui/Button';
import DropDown from '@/components/ui/DropDown/DropDown';
import DropDownItem from '@/components/ui/DropDown/DropDownItem';

export default {
    name: 'DropDownButton',
    components: {
        Button,
        DropDown,
        DropDownItem,
    },
    props: {
        dropDownItems: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            showDropDown: false,
        };
    },
    computed: {
        buttonIcon() {
            return this.showDropDown ? 'arrow-down' : 'arrow-up';
        },
    },
    methods: {
        onClickOutside() {
            if (this.showDropDown) {
                this.showDropDown = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown-button {
    position: relative;
    display: inline-block;
}

.dropdown-button-dropdown {
    position: absolute;
    top: -10px;
    right: 0;
    transform: translateY(-100%);
}
</style>
